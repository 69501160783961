import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import JsBarcode from 'jsbarcode';

export default {
  name: 'assetViewDetails',
  components: { DatePicker },
  props: ['rowData','assetNum'],
  watch: {
    currentPage: function() {
      this.generateAsset();
    },
    perPage: function() {
      this.currentPage = 1;
      this.generateAsset();
    },
    tags() {
      this.adjustTagsWidth();
    }
  },
  data() {
    return {
      totalElements: null,
      currentPage: 1,
      perPage: 1,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      isOpen: false,
      editMode: false,
      unsubscribe: null,
      assetData: null,
      alreadyCreated: null,
      showCopyModal: false,
      grnassets: [
        {
          label: 'Purchase Details :-',
          class: 'purchase'
        },
        {
          key: 'le_name',
          label: 'Legal Entity',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'ou_name',
          label: 'Operating Unit',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'vendor_name',
          label: 'Vendor Name',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_number',
          label: 'PO Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'po_date',
          label: 'PO Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        // {
        //   key: 'qty',
        //   label: ' PO Quantity',
        //   value: null,
        //   isCalender: 0,
        //   editsFlag: 1
        // },
        {
          key: 'item_code',
          label: 'Item Code',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'item_desc',
          label: 'Item Description',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'price',
          label: 'Price',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn',
          label: 'GRN Details :-',
          class: 'grnDetails'
        },
        {
          key: 'grn_num',
          label: 'GRN Number',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'grn_date',
          label: 'GRN Date',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'fa_cat',
          label: 'FA Category',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          key: 'hsn_code',
          label: 'HSN / SAC code',
          value: null,
          isCalender: 0,
          editsFlag: 1
        },
        {
          label: 'Asset Details:-'
        },
        {
          key: 'date_received',
          label: 'Date Received',
          value: null,
          isCalender: 1,
          editsFlag: 2,
          classes: null
        },
        {
        key: 'invoice_date',
        label: 'Invoice Date',
        value: null,
        isCalender: 1,
        editsFlag: 2,
        classes: null
      },
        {
          key: 'serial_number',
          label: 'Serial Number',
          value: null,
          isCalender: 6,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'hdd_ssd',
          label: 'HDD/SSD',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'wireless_mac_address',
          label: 'Wireless Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'lan_mac_address',
          label: 'LAN Mac Address',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'end_of_warranty',
          label: 'End Of Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'extended_warranty',
          label: 'Extended Warranty',
          value: null,
          isCalender: 5,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'type_of_warranty',
          label: 'Type Of Warranty',
          value: null,
          meaning: null,
          isCalender: 7,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'notes',
          label: 'Notes',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'tags',
          label: 'Tags',
          value: [],
          isCalender: 2,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'manufacturer',
          label: 'Manfacturer',
          value: null,
          meaning: null,
          isCalender: 8,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'model',
          label: 'Model',
          value: null,
          meaning: null,
          isCalender: 9,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'processor',
          label: 'Processor',
          value: null,
          meaning: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'color',
          label: 'Color',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'group_item_category',
          label: 'Group Item Category',
          value: null,
          isCalender: 0,
          editsFlag: 2,
          classes: null
        },
        {
          key: 'department',
          label: 'Department',
          value: null,
          meaning: null,
          isCalender: 4,
          editsFlag: 2,
          classes: null
        }
        // {
        //   key: 'le_id',
        //   value: null
        // }
      ],
      parent_value_set_id: null,
      assetId: null,
      copyData: null,
      documentNo: null,
      showValueSetModal: false,
      itemType: null,
      assetItem: null,
      itemId: null,
      data: null,
      checkFlag: null,
      checkedFlag: null,
      regexPattern: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\.[0-9a-fA-F]{4}\.[0-9a-fA-F]{4})$/,
      getDate: null,
      modelValueSetId: null,
      barcode:null,
    };
  },
  mounted() {
    // if (this.rowData) {
    //   this.copyData = this.rowData;
    //   // this.mapRowData(this.rowData);
    //   this.generateAsset();
    //   this.alreadyCreatedQty();
    // } else {
    //   this.editMode = true;
    // }
    this.getAssetViewDetails()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          // this.editMode = !this.editMode;
        }
      }
    });
  },
  computed: {
    // limitedItems() {
    //   return this.grnassets.slice(0, this.grnassets.length - 1);
    // }
    disabledDates() {
      return date => {
        const currentDate = new Date();
        const previousDate = new Date(this.grnassets[6].value);
        previousDate.setHours(0, 0, 0, 0);
        return date > currentDate || date < previousDate;
      };
    }
  },
  methods: {
    setDefaultModel() {
      const modelValue = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER,
        this.grnassets[26].value
      );
      modelValue.then(key => {
        this.modelValueSetId = key[0].value_set_dtl_id
      });
    },
    getAssetItem() {
      this.grnassets.map(item => (item.classes = null));
      this.checkFlag = null;
      const payload = {
        item_id: this.itemId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetItemById', payload)
        .then(resp => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 200) {
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.data = resp.data.data;
            for (let i in this.data) {
              for (let j = 0; j < this.grnassets.length; j++) {
                if (i === this.grnassets[j].key && this.data[i] === true) {
                  this.grnassets[j].classes = 'mandatory';
                  this.checkFlag++;
                }
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    mapRowData(rowData) {
      if (rowData !== null) {
        for (let i = 0; i < this.grnassets.length; i++) {
          this.grnassets[i].value = rowData[this.grnassets[i].key];
        }
      }
    },
    alreadyCreatedQty() {
      const payload = {
        po_number: this.rowData.po_number,
        grn_num: this.rowData.grn_num,
        item_code: this.rowData.item_code
      };
      this.loader = true;
      this.$store
        .dispatch('assets/alreadyCreatedQty', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.alreadyCreated = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTags(id) {
      const payload = {
        asset_id: id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetTags', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.grnassets[25].value = [];
            resp.data.data.map(item => {
              this.grnassets[25].value.push(item);
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAssetViewDetails() {
      const payload = {
        assetNum:this.assetNum.asset_number?this.assetNum.asset_number:this.assetNum.doc_num
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetViewDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.mapRowData(result);
            this.documentNo = resp.data.data.doc_num;
            // this.totalElements = resp.data.data.total_elements;
            this.assetId = resp.data.data.asset_id;
            this.itemType = resp.data.data.item_type_meaning;
            this.assetItem = resp.data.data.item_name;
            this.itemId = resp.data.data.fa_asset_item_id;
            this.grnassets[32].meaning =
              resp.data.data.department_meaning;
            this.grnassets[27].meaning =
              resp.data.data.manufacturer_name_meaning;
            this.grnassets[28].meaning = resp.data.data.model_meaning;
            this.grnassets[24].meaning =
              resp.data.data.type_of_warranty_meaning;
            this.getDate = resp.data.data.date_received;
            if(this.itemType==="ASSET"){
              setTimeout(() => {
                this.showBarcode(resp.data.data.bar_code)
              }, 0);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
 
    openManufacturerBaseModelType() {
      if (this.grnassets[26].value) {
        this.openValueSetModal('FA_ASSET_MODEL_TYPE');
      } else {
        alert('Please Select Type Of Manufacturer');
      }
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE) {
        this.parent_value_set_id = this.modelValueSetId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_TAGS) {
        this.grnassets[25].value.push(item.value_code);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_DEPARTMENT) {
        this.grnassets[31].value = item.value_code;
        this.grnassets[31].meaning = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.ASSET_TYPE_OF_WARRANTY
      ) {
        this.grnassets[23].value = item.value_code;
        this.grnassets[23].meaning = item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MANUFACTURER
      ) {
        this.grnassets[26].value = item.value_code;
        this.grnassets[26].meaning = item.value_meaning;
        this.modelValueSetId=item.value_set_dtl_id;
        this.grnassets[27].value = null;
        this.grnassets[27].meaning = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_MODEL_TYPE
      ) {
        this.grnassets[27].value = item.value_code;
        this.grnassets[27].meaning = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'ASSET_TYPE_OF_WARRANTY':
          this.grnassets[23].value = null;
          this.grnassets[23].meaning = null;
          break;
        case 'FA_ASSET_MANUFACTURER':
          this.grnassets[26].value = null;
          this.grnassets[26].meaning = null;
          break;
        case 'FA_ASSET_MODEL_TYPE':
          this.grnassets[27].value = null;
          this.grnassets[27].meaning = null;
          break;
      }
    },
    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },
    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    },

    containsSpecialCharacters(inputString) {
      const specialCharactersPattern = /[!@#$%^&*()_+{}[\]<>,.?~\\/-]/;

      let res = specialCharactersPattern.test(inputString);
      if (res === true) {
        alert("Special characters are not allowed except ':' or '-'");
      }
    },

    disabledDatesWarranty(date) {
      return commonHelper.disabledDates(date, this.grnassets[16].value);
    },

    dateFunc() {
      if (!this.grnassets[16].value) {
        alert('Select Date Received first');
      }
    },
    generateBarcode(){
      // Generate the barcode
      JsBarcode("#barcode", this.barcode, {
       format: 'CODE128',
       displayValue: true,
       height: 100,
       width: 2.8,
       fontSize:30,
     });

     // Get the SVG content
     const svgContent = document.getElementById("barcode").outerHTML;

     const barcodeNumber = this.$refs.logo.innerText;
     // Open a new window to print
     const printWindow = window.open('', '_blank');

     // Write the printable content to the window
     printWindow.document.write(`
       <html>
         <head>
           <title>Barcode Print</title>
           <style>
             @media print {
               body { margin: 0; }
             }
             @page { size: auto; }
           </style>
         </head>
         <body>
           <div style="text-align: center">
             <h1 style="font-weight: bold; padding-bottom:0px; margin-bottom:30px; font-size:xxx-large">${barcodeNumber}</h1>
             <br>
             ${svgContent}
           </div>
         </body>
       </html>
     `);

     // Print the window
     printWindow.print();
     printWindow.close();
   },
   showBarcode(barco){
    this.barcode=barco
      JsBarcode("#barcode", this.barcode, {
        format: 'CODE128',
        displayValue: true,
        height: 100,
        width: 2.8,
        fontSize:30,
      });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
