<template>
  <div id="chart-container2"></div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import highchartsDark from 'highcharts/themes/dark-unica';
Highcharts3D(Highcharts);
highchartsDark(Highcharts);

export default {
  name: 'pieChart2',
  props: ['chart'],
  mounted() {
   const chart = Highcharts.chart('chart-container2', {
      chart: {
        type: 'pie',
        height: 330,
      },
      title: {
        text: 'Assets',
        fontWeight: 'bolder'
      },
      plotOptions: {
        pie: {
          depth: 45,
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}% {point.name}',
            style: {
              fontFamily: 'Open Sans',
            }
          },
          showInLegend: true
        }
      },
      tooltip:{
       style: {
              fontFamily: 'Open Sans',
            },
       pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      // series:{
      //   cursor:'pointer'
      // },
      series: [
        {
          name: 'Data',
          size: '60%',
          data: this.chart,
          cursor:'pointer',
          events:{
            click:function(){
              alert("Row1")
            }
          }
        }
      ]
    });
    const renderer = chart.renderer;
    renderer.text('Matched Assets', 420, 150).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder',
      fontFamily:'Open Sans'

    }).attr({
      zIndex: 5
    }).add();
    const renderer1 = chart.renderer;
    renderer1.text('Mismatched Assets', 50, 280).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder',
      fontFamily:'Open Sans'
    }).attr({
      zIndex: 5
    }).add();
     const renderer2 = chart.renderer;
    renderer2.text('Not Found Assets', 40, 90).css({
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bolder',
      fontFamily:'Open Sans'

    }).attr({
      zIndex: 5
    }).add();
  }
};
</script>