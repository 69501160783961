import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'remedyDetails',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
  },
  props: ['selectedRow','getDetails', 'remedyMenuId' , 'auditData'],
  watch: {
    currentPage: function() {
      //   this.getassetMasterItems();
    },
    perPage: function() {
      this.currentPage = 1;
      //   this.getassetMasterItems();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      payload: null,
      chartData: [],
      chartData1: [],
      initiatedBy: 'Connaissant',
      status: 'Completed',
      chartFlag: false,
      assetRemedyData: [  
      //   {
      //   asset_number: '34625251-1',
      //   serial_number: 7431254352173,
      //   asset_type: 'ASSET',
      //   status: 'Mismatched',
      //   action:'Match Physical to Logical'
      // },
      // {
      //   asset_number: '346251323-2',
      //   serial_number: 3123213213,
      //   asset_type: 'ASSET',
      //   status: 'Mismatched',
      //   action:'Match Physical to Logical'
      // },
      // {
      //   asset_number: '34623123-10',
      //   serial_number: 564345423213,
      //   asset_type: 'ASSET',
      //   status: 'Mismatched',
      //   action:'Match Physical to Logical'
      // },
      // {
      //   asset_number: '3462315645-7',
      //   serial_number: 564345423213,
      //   asset_type: 'ASSET',
      //   status: 'Matched',
      //   action:'Match Physical to Logical'
      // },
      // {
      //   asset_number: '234213-120',
      //   serial_number: 564345423213,
      //   asset_type: 'ASSET',
      //   status: 'Not Found',
      //   action:'Match Physical to Logical'
      // },
      // {
      //   asset_number: '34323432-11',
      //   serial_number: 564345423213,
      //   asset_type: 'ASSET',
      //   status: 'Not Found',
      //   action:'Match Physical to Logical'
      // }
    ],
      assetRemedyFields: [
        {
          key: 'asset_number',
        },
        {
          key: 'serial_no',
        },
        {
          key: 'asset_type',
        },
        {
          key: 'status',
        },
        // {
        //   key: 'created_by',
        // },
        // {
        //   key: 'updated_by',
        // },
        // {
        //   key: 'approved_by',
        // },
        {
          key:'action_meaning',
          label:'Action',
        },
        {
          key:'action',
          class: 'd-none'
        },
        {
          key:'asset_id',
          class: 'd-none'
        },
        {
          key: 'remarks',
        }
      ],
      selectAllCheckBox: false,
      editMode: false,
      mismatchedActive: false,
      matchedActive: false,
      notFoundActive: false,
      checkFlag:false,
      showValueSetModal:false,
      specIndex:null,
    };
  },
  validations: {},
  mounted() {
    this.assetRemedyData=this.selectedRow
    // for(let i=0;i<this.assetRemedyData.length;i++){
    //   if(this.assetRemedyData[i].status=='MATCHED'){
    //     this.assetRemedyData.splice(i,1)
    //   }
    // }

    this.getDetails()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
            this.postRemedy();
        } else if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.REMEDY_ACTION) {
        this.assetRemedyData[this.specIndex].action_meaning = item.value_meaning;
          this.assetRemedyData[this.specIndex].action =item.value_code;
          setTimeout(() => {
            this.assetRemedyData = JSON.parse(
              JSON.stringify(this.assetRemedyData)
            );
          }, 0);
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    // clearVsetValues(vsetCode, index) {
    //   this.specIndex = index;
    //   this.vsetCode = vsetCode;
    //   if (this.vsetCode === appStrings.VALUESETTYPE.REMEDY_ACTION) {
    //     this.assetRemedyData[this.specIndex].action_meaning = null;
    //       this.assetRemedyData[this.specIndex].action =null;
    //       setTimeout(() => {
    //         this.assetRemedyData = JSON.parse(
    //           JSON.stringify(this.assetRemedyData)
    //         );
    //       }, 0);
    //   }
    // },
    postRemedy() {
      const payload = {
        audit_hdr_id:this.auditData.audit_hdr_id,
        remedy_batch_id:0,
        remedy_dtls:[],
        menu_id:this.remedyMenuId
      };
      this.assetRemedyData.map(item => {
          payload.remedy_dtls.push({
            asset_id: item.asset_id,
            action: item.action,
            remarks: item.remarks?item.remarks:null
          });
      });
      this.loader = true;
      this.$store
        .dispatch('assets/postAuditRemedy', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.message;
            this.editMode = false;
            this.$emit('showHideRemedyModal',false)
            this.$emit('getRemedyHeader')
            this.$emit('getAuditData',true)
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.selectedRow.map(ele=>{
      ele.action=null;
      ele.action_meaning=null
    })
    this.unsubscribe();
  }
};
