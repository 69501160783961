import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'scanAssets',
  components: {
    appStrings,
    DatePicker,
    commonHelper
  },
  props: ['rowScanData'],
  watch: {
    currentPage: function() {
      //   this.getassetMasterItems();
    },
    perPage: function() {
      this.currentPage = 1;
      //   this.getassetMasterItems();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      payload: null,
      matchFlag: false,
      scanAssetsData: [
        {
          bar_code: null,
          scan_complete: false
        }
      ],
      scanAssetsFields: [
        // { key: 'add',
        //   stickyColumn: true,
        // },
        { key: 'remove', stickyColumn: true },
        {
          key: 'bar_code'
        },
        {
          key: 'scan_complete',
          class: 'd-none'
        }
      ],
      scannedAssetsData: [],
      scannedAssetsFields: [
        {
          key: 'bar_code'
        },
        {
          key: 'asset_num',
          label:'Asset Number'
        },
        {
          key:'serial_no',
          label:'Serial Number'
        },
        {
          key: 'asset_type'
        },
        // {
        //   key: 'asset_item'
        // },
        {
          key:'description',
          label:'Item Description'
        },
        {
          key:'view_details'
        }
        
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      editMode: false,
      mismatchedActive: false,
      matchedActive: false,
      notFoundActive: false,
      checkFlag: false,
      assetNum: null,
      duplicateVal: null
    };
  },
  validations: {},
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          //   this.showHideAddFaLocationModal(true);
          this.postBarcodes();
        }
        // else if (actionName === 'edit' || actionName === 'update') {
        //   this.editMode = true;
        //   this.eventBus.$emit('auditFlag', this.editMode);
        // }
      }
    });
    this.getAssetDetails()
  },
  methods: {
    getData() {},
    addNewRow(i, d) {
      this.duplicateVal = null;
      for (let i = 0; i < this.scanAssetsData.length; i++) {
        if (d.index !== i && d.value == this.scanAssetsData[i].bar_code) {
          this.duplicateVal = this.scanAssetsData[i].bar_code;
        }
      }
      if (this.duplicateVal) {
        this.makeToast('Duplicate value found:' + this.duplicateVal);
        this.scanAssetsData[i].bar_code=null
      } else {
        this.scanAssetsData.push({
          bar_code: null,
          scan_complete: null
        });
        this.scanAssetsData[i].scan_complete = true;
      }
    },
    makeToast(content) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    },
    removeRow(index) {
      if (this.scanAssetsData.length - 1 == index) {
        this.scanAssetsData.splice(index, 1);
        this.scanAssetsData[index - 1].scan_complete = false;
        this.scanAssetsData[index - 1].bar_code = null;
      } else {
        this.scanAssetsData.splice(index, 1);
        // this.scanAssetsData[index].scan_complete = false;
        // this.scanAssetsData[index].bar_code = null;
      }
    },
    postBarcodes() {
      const payload = {
        audit_hdr_id: this.rowScanData.audit_hdr_id,
        audit_dtl: {
          audit_dtl_id: this.rowScanData.audit_dtl_id,
          audit_line: [
            // {
            // audit_dtl_id: 0,
            // audit_hdr_id: 0,
            // quantity: null,
            // item_code_vset: null,
            // bar_code: null,
            // }
          ]
        }
      };
      this.scanAssetsData.map(ele => {
        if (ele.bar_code !== null) {
          payload.audit_dtl.audit_line.push({
            bar_code: ele.bar_code
          });
        }
      });
      this.loader = true;
      this.$store
        .dispatch('assets/postAddEditBarcodes', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            (this.isSuccess = true), (this.responseMsg = resp.data.message);
            // this.editMode = false;
            this.totalRows = resp.data.data.total_elements;
            this.getAssetDetails()
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAssetDetails() {
      const payload = {
        dtlId: this.rowScanData.audit_dtl_id,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.scannedAssetsData = resp.data.data;
            // this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
