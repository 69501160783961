import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import scanAssets from './scanAssets';
import consumableAssets from "./consumableAssets"

export default {
  name: 'assetScanDetails',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
    scanAssets,
    consumableAssets
  },
  props: ['rowData','getAuditHistory','locAccessFlag'],
  watch: {
    currentPage: function() {
      //   this.getassetMasterItems();
    },
    perPage: function() {
      this.currentPage = 1;
      //   this.getassetMasterItems();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      payload: null,
      createdBy: null,
      binCombination: null,
      status: null,
      startDate: null,
      endDate: null,
      auditScanOtherDetailsData: [],
      auditScanOtherDetailsFields: [
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class: 'stickyColumn col-sm-1 wid'
        // },
        {
          key: 'bin_combination',
          class: 'textDecos'
        },
        {
          key: 'scan_complete',
          class: 'textDecos'
        },
        {
          key: 'scan_closed',
          class: 'textDecos'
        },
        {
          key: 'scan_assets',
          class: 'textDecos'
        },
        {
          key: 'add_consumable',
          class: 'textDecos'
        }
      ],
      auditScanYouDetailsData: [
        // {
        //   bin_combination:'R1S3B3'
        // }
      ],
      auditScanYouDetailsFields: [
        // {
        //   key: 'selectBox',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class: 'stickyColumn col-sm-1 wid'
        // },
        {
          key: 'bin_combination',
          class: 'textDecos'
        },
        {
          key: 'scanning_complete_flag',
          class: 'textDecos'
        },
        {
          key: 'scanned_status',
          class: 'textDecos'
        },
        {
          key: 'scan_assets',
          class: 'textDecos'
        },
        {
          key: 'add_consumable',
          class: 'textDecos'
        },
        {
          key:'audit_dtl_id',
          class:'d-none'
        }
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      editMode: false,
      mismatchedActive: false,
      matchedActive: false,
      notFoundActive: false,
      checkFlag: false,
      assetNum: null,
      showHideScanAssetsModalFlag: false,
      showHideAddConsumableModalFlag:false,
      rowScanData: null,
      auditStatus:null,
      accessButtons:{}
    };
  },
  validations: {},
  mounted() {
    // this.createdBy = this.rowData.created_by;
    // this.startDate = this.rowData.start_date;
    // this.endDate = this.rowData.end_date;
    this.getAuditHeader();
    this.getAuditHeaderDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showHideScanAssetsModalFlag && !this.showHideAddConsumableModalFlag && this.editMode) {
          //   this.showHideAddFaLocationModal(true);
          this.saveAddEditAuditData()
          
        } else if (actionName === 'update') {
          this.editMode = true;
          // this.eventBus.$emit('auditFlag', this.editMode);
        }
      }
    });
  },
  methods: {
    getData() {},
    addNewRow(index) {
      this.auditScanOtherDetailsData.push({
        bin_combination: null,
        scan_complete: null,
        scan_closed: null,
        scan_assets: null,
        add_consumable: null
      });
      this.$nextTick(() => {
        // Find the input element in the new row using ref
        const inputRef = `input-${index + 1}`;
        const inputElement = this.$refs[inputRef];

        // Focus on the input element
        if (inputElement) {
          inputElement.focus();
        }
      });
    },
    removeRow(index) {
      this.auditScanOtherDetailsData.splice(index, 1);
    },
    showHideScanAssetsModal(flag, item) {
      this.showHideScanAssetsModalFlag = flag;
      this.rowScanData = item;
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHideAddConsumableModal(flag,item){
      this.showHideAddConsumableModalFlag=flag
      this.rowScanData = item;
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    clearUser() {
      this.binCombination = null;
    },

    getAuditHeaderDetails() {
      const payload = {
        // _page: this.currentPage - 1,
        // _limit: this.perPage,
        hdrId: this.rowData.audit_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAuditHeaderDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.auditScanYouDetailsData = resp.data.data;
            this.auditScanYouDetailsData.map(ele => {
              if (ele.scanned_status == true) {
                ele.scanned_status_flag = true;
              } else{
                ele.scanned_status_flag = false;
              }
            });

            // this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBins() {
      const payload = {
        hdr_id: this.rowData.audit_hdr_id,
        bin_combination: this.binCombination
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getBinDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.auditScanYouDetailsData = resp.data.data;
            // this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changeFlag(data) {
      if (!data.scanning_complete_flag) {
        data.scanned_status = false;
      }
    },
    saveAddEditAuditData() {
      const payload = {
        "audit_hdr_id": this.rowData.audit_hdr_id, 
        "assetAuditDtlDto": [] 
      };
      this.auditScanYouDetailsData.map(ele=>{
        payload.assetAuditDtlDto.push({
          audit_dtl_id:ele.audit_dtl_id,
          scanned_status:ele.scanned_status,
          scanning_complete_flag:ele.scanning_complete_flag
        })
      })
      {
        this.loader = true;
      this.$store
        .dispatch('assets/postAuditScanDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true, 
            this.responseMsg = resp.data.message;
            this.totalRows = resp.data.data.total_elements;
            this.getAuditHeaderDetails();
            this.getAuditHistory()
            this.getAuditHeader()
          }
          else{
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    getAuditHeader(){
      const payload = {
        // _page: this.currentPage - 1,
        // _limit: this.perPage,
        hdrId: this.rowData.audit_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAuditHeader', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
             this.createdBy=resp.data.data[0].created_by;
             this.auditStatus=resp.data.data[0].audit_status;
             this.startDate=resp.data.data[0].start_date             
             this.endDate=resp.data.data[0].end_date
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
